import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './src/components/apolloClient'
import { AppProvider } from './src/components/context'

import Amplify from 'aws-amplify'
import awsconfig from './src/aws-exports'

Amplify.configure(awsconfig)

import '@fontsource/dm-serif-text/400.css'
import '@fontsource/libre-franklin/400.css'

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <AppProvider>{element}</AppProvider>
    </ApolloProvider>
  )
}

import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { WpCart } from '../../../graphql-types'

const defaultState: AppState = {
  setCart: () => {},
  cart: undefined,
}

const AppContext = createContext(defaultState)

const AppProvider: FunctionComponent = ({ children }) => {
  const [cart, setCart] = useState<AppState['cart']>(defaultState.cart)

  return (
    <AppContext.Provider value={{ cart, setCart }}>
      {children}
    </AppContext.Provider>
  )
}

const useAppState = () => useContext(AppContext)

type AppState = {
  cart?: WpCart
  setCart: Dispatch<SetStateAction<WpCart | undefined>>
}

export { AppProvider, useAppState }

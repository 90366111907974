import { extendTheme, ThemeConfig, ThemeOverride } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  //useSystemColorMode: true,
}

const themeOverride: ThemeOverride = {
  colors: {
    aqua: {
      50: '#f0faf7',
      100: '#e2f5ef',
      200: '#c4ebde',
      300: '#a7e1ce',
      400: '#89d7bd',
      500: '#6ccdad',
      600: '#56a48a',
      700: '#417b68',
      800: '#2b5245',
      900: '#162923',
    },
    mistyRose: {
      50: '#fffcfc',
      100: '#fef9f9',
      200: '#fdf3f2',
      300: '#fdedec',
      400: '#fce7e5',
      500: '#fbe1df',
      600: '#c9b4b2',
      700: '#978786',
      800: '#645a59',
      900: '#322d2d',
    },
    msuGreen: {
      50: '#e9edec',
      100: '#d4dbd9',
      200: '#a8b7b3',
      300: '#7d948d',
      400: '#517067',
      500: '#264c41',
      600: '#1e3d34',
      700: '#172e27',
      800: '#0f1e1a',
      900: '#080f0d',
    },
  },
  components: {
    Accordion: {
      baseStyle: {
        button: {
          py: '3',
          ['&[aria-expanded="true"]']: {
            bg: 'whiteAlpha.700',
          },
          [':hover']: {
            bg: 'whiteAlpha.700',
          },
          [':focus']: {
            outline: 'none',
            boxShadow: `0 0 0 3px #ffb500`,
          },
          [':focus:not(:focus-visible)']: {
            boxShadow: 'none',
          },
          [':focus-visible']: {
            boxShadow: `0 0 0 3px #ffb500`,
          },
        },
        container: {
          borderColor: 'msuGreen.200',
        },
        panel: {
          bg: 'whiteAlpha.700',
        },
      },
    },
    Button: {
      baseStyle: {
        [':focus']: {
          outline: 'none',
          boxShadow: `0 0 0 3px #ffb500`,
        },
        [':focus:not(:focus-visible)']: {
          boxShadow: 'none',
        },
        [':focus-visible']: {
          boxShadow: `0 0 0 3px #ffb500`,
        },
      },
      variants: {
        ghost: (props) => ({
          [':hover']: {
            background: 'none',
          },
        }),
        solid: (props) => ({
          [':hover']: {
            color: props.colorMode === 'dark' ? 'msuGreen.500' : 'white',
          },
        }),
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: 'msuGreen.300',
          _focus: {
            outline: 'none',
            boxShadow: `0 0 0 3px #ffb500`,
          },
        },
      },
    },
    Input: {
      variants: {
        outline: (props) => ({
          field: {
            borderColor: 'msuGreen.300',
            bgColor:
              props.colorMode === 'dark' ? 'whiteAlpha.100' : 'whiteAlpha.900',
            height: 'auto',
            lineHeight: '2.4rem',
            [':hover:not([aria-invalid="true"])']: {
              borderColor: 'msuGreen.400',
            },
            [':focus']: {
              outline: 'none',
              boxShadow: `0 0 0 3px #ffb500`,
            },
            [':focus:not(:focus-visible)']: {
              boxShadow: 'none',
            },
            [':focus-visible']: {
              boxShadow: `0 0 0 3px #ffb500`,
            },
          },
        }),
      },
    },
    NumberInput: {
      baseStyle: {
        stepper: {
          borderColor: 'msuGreen.200',
        },
      },
      variants: {
        outline: (props) => ({
          field: {
            borderColor: 'msuGreen.300',
            bgColor:
              props.colorMode === 'dark' ? 'whiteAlpha.100' : 'whiteAlpha.900',
            height: 'auto',
            lineHeight: '2.4rem',
            [':hover:not([aria-invalid="true"])']: {
              borderColor: 'msuGreen.400',
            },
            [':focus']: {
              outline: 'none',
              boxShadow: `0 0 0 3px #ffb500`,
            },
            [':focus:not(:focus-visible)']: {
              boxShadow: 'none',
            },
            [':focus-visible']: {
              boxShadow: `0 0 0 3px #ffb500`,
            },
          },
        }),
      },
    },
    Select: {
      variants: {
        outline: (props) => ({
          field: {
            borderColor: 'msuGreen.300',
            bgColor:
              props.colorMode === 'dark' ? 'whiteAlpha.100' : 'whiteAlpha.900',
            height: 'auto',
            lineHeight: '2.4rem',
            [':hover:not([aria-invalid="true"])']: {
              borderColor: 'msuGreen.400',
            },
          },
        }),
      },
    },
    Table: {
      baseStyle: {
        th: {
          borderColor: 'msuGreen.200',
          fontFamily: 'body',
          textTransform: 'none',
        },
        td: {
          borderColor: 'msuGreen.200',
        },
      },
      variants: {
        simple: (props) => ({
          th: {
            borderBottomColor: 'msuGreen.200',
          },
          td: {
            borderBottomColor: 'msuGreen.200',
          },
        }),
      },
    },
    Textarea: {
      variants: {
        outline: (props) => ({
          borderColor: 'msuGreen.300',
          bgColor:
            props.colorMode === 'dark' ? 'whiteAlpha.100' : 'whiteAlpha.900',
          height: 'auto',
          lineHeight: '1.4rem',
          [':hover:not([aria-invalid="true"])']: {
            borderColor: 'msuGreen.400',
          },
          [':focus']: {
            outline: 'none',
            boxShadow: `0 0 0 3px #ffb500`,
          },
          [':focus:not(:focus-visible)']: {
            boxShadow: 'none',
          },
          [':focus-visible']: {
            boxShadow: `0 0 0 3px #ffb500`,
          },
        }),
      },
    },
  },
  fonts: {
    heading: 'DM Serif Text',
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu"',
  },
  styles: {
    global: (props) => ({
      'html, body': {
        bgColor: props.colorMode === 'dark' ? 'msuGreen' : 'mistyRose.200',
        color: props.colorMode === 'dark' ? 'whiteAlpha.900' : 'msuGreen.500',
      },
      a: {
        borderRadius: 'md',
        display: 'inline-block',
        [':focus']: {
          outline: 'none',
          boxShadow: `0 0 0 3px #ffb500`,
        },
        [':focus:not(:focus-visible)']: {
          boxShadow: 'none',
        },
        [':focus-visible']: {
          boxShadow: `0 0 0 3px #ffb500`,
        },
      },
    }),
  },
}

const theme = extendTheme({ config, ...themeOverride })
export default theme

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-auth-complete-tsx": () => import("./../../../src/pages/checkout/auth-complete.tsx" /* webpackChunkName: "component---src-pages-checkout-auth-complete-tsx" */),
  "component---src-pages-checkout-order-received-tsx": () => import("./../../../src/pages/checkout/order-received.tsx" /* webpackChunkName: "component---src-pages-checkout-order-received-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-course-wp-course-slug-tsx": () => import("./../../../src/pages/course/{wpCourse.slug}.tsx" /* webpackChunkName: "component---src-pages-course-wp-course-slug-tsx" */),
  "component---src-pages-groups-index-tsx": () => import("./../../../src/pages/groups/index.tsx" /* webpackChunkName: "component---src-pages-groups-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instructor-wp-instructor-slug-tsx": () => import("./../../../src/pages/instructor/{wpInstructor.slug}.tsx" /* webpackChunkName: "component---src-pages-instructor-wp-instructor-slug-tsx" */),
  "component---src-pages-product-wp-product-slug-tsx": () => import("./../../../src/pages/product/{wpProduct.slug}.tsx" /* webpackChunkName: "component---src-pages-product-wp-product-slug-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-wp-page-slug-tsx": () => import("./../../../src/pages/{wpPage.slug}.tsx" /* webpackChunkName: "component---src-pages-wp-page-slug-tsx" */)
}

